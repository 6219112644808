<template>
  <b-sidebar
    id="add-new-promotion-sidebar"
    :visible="isEditPromotionSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-promotion-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Edit Consult Price</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group :label="$t('Form.Name')" label-for="name">
              <b-form-input
                id="name"
                v-model="stateData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Promotion"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Price -->
          <validation-provider
            #default="validationContext"
            name="Price"
            rules="required"
          >
            <b-form-group label="Price" label-for="price">
              <b-form-input
                id="price"
                v-model="stateData.price"
                autofocus
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="Consult price name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Date Start"
            rules="required"
          >
            <b-form-group label="Date Start" label-for="date-start">
              <flat-pickr
                id="date-start"
                v-model="stateData.date_start"
                :config="config"
                class="form-control"
                placeholder="MM-DD-YYYY"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- end Date -->
          <validation-provider
            #default="validationContext"
            name="Date End"
            rules="required"
          >
            <b-form-group label="Date End" label-for="end_date">
              <flat-pickr
                id="end_date"
                v-model="stateData.date_end"
                :config="config"
                class="form-control"
                placeholder="MM-DD-YYYY"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- laboratories -->
          <validation-provider
            #default="validationContext"
            name="Laboratories"
            rules=""
          >
            <b-form-group label="Laboratories" label-for="laboratories">
              <v-select
                id="laboratories"
                v-model="stateData.laboratories"
                :state="getValidationState(validationContext)"
                :options="laboratoriesOptions"
                multiple
                :reduce="(val) => val.id"
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- tests -->
          <validation-provider
            #default="validationContext"
            name="Tests"
            rules=""
          >
            <b-form-group label="Tests" label-for="test">
              <v-select
                id="test"
                v-model="stateData.tests"
                :state="getValidationState(validationContext)"
                :options="testOptions"
                multiple
                :reduce="(val) => val.id"
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- procedures -->
          <validation-provider
            #default="validationContext"
            name="Procedures"
            rules=""
          >
            <b-form-group label="Procedures" label-for="procedure">
              <v-select
                id="procedure"
                v-model="stateData.procedures"
                :state="getValidationState(validationContext)"
                :options="procedureOptions"
                multiple
                :reduce="(val) => val.id"
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- diagnostic imagings -->
          <validation-provider
            #default="validationContext"
            name="Diagnostic Imagings"
            rules=""
          >
            <b-form-group label="Diagnostic Imagings" label-for="diagnostic">
              <v-select
                id="diagnostic"
                v-model="stateData.diagnostic_imagings"
                :state="getValidationState(validationContext)"
                :options="diagnosticImagingOptions"
                multiple
                :reduce="(val) => val.id"
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- status -->
          <validation-provider
            #default="validationContext"
            name="Status"
            rules="required"
          >
            <b-form-group :label="$t('Form.Status')" label-for="status">
              <v-select
                id="status"
                v-model="stateData.status"
                :state="getValidationState(validationContext)"
                :options="optionsStatus"
                :reduce="(val) => val.value"
                :clearable="false"
                label="text"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="loading"
            >
              <span v-if="!loading">Edit</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-danger"
              @click="hide"
            >
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SpinnerLoading from "@/components/SpinnerLoading";

import vSelect from "vue-select";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { onMounted, ref } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";

import axiosD from "@/services/admin/consults/consultPrices";
import axiosL from "@/services/admin/consults/laboratories";
import axiosT from "@/services/admin/consults/tests";
import axiosP from "@/services/admin/consults/procedures";
import axiosDI from "@/services/admin/consults/diagnosticImages";
import axiosTR from "@/services/admin/consults/treatments";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    SpinnerLoading,

    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditPromotionSidebarActive",
    event: "update:is-edit-promotion-sidebar-active",
  },
  props: {
    isEditPromotionSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      required,
      config: {
        dateFormat: "m-d-Y",
        allowInput: true,
      },
    };
  },
  setup(props, { emit }) {
    const optionsStatus = [
      { text: "Active", value: true },
      { text: "Inactive", value: false },
    ];

    onMounted(() => {
      getData();
      getDataItems();
    });

    const stateData = ref({});

    const getData = () => {
      const { id } = props.itemEdit.value;
      axiosD.consultPromoView(id).then(({ registro }) => {
        stateData.value = registro;
      });
    };

    const laboratoriesOptions = ref([]);
    const laboratoriesPackOptions = ref([]);
    const testOptions = ref([]);
    const procedureOptions = ref([]);
    const diagnosticImagingOptions = ref([]);
    const treatmentsOptions = ref([]);

    const getDataItems = () => {
      axiosL.laboratoryList(200).then(({ data }) => {
        laboratoriesOptions.value = data;
        data.forEach((item) => {
          if (item.categories[0].price) {
            if (
              !laboratoriesPackOptions.value.some(
                (itemFind) => itemFind.id === item.categories[0].id
              )
            ) {
              laboratoriesPackOptions.value.push(item.categories[0]);
            }
          }
        });
      });
      axiosT.testList(20).then(({ data }) => {
        testOptions.value = data;
      });
      axiosP.procedureList(20).then(({ data }) => {
        procedureOptions.value = data;
      });
      axiosDI.diagnosticImageList(20).then(({ data }) => {
        diagnosticImagingOptions.value = data;
      });
      axiosTR.treatmentList(20).then(({ data }) => {
        treatmentsOptions.value = data;
      });
    };

    const resetuserData = () => {
      stateData.value = JSON.parse(JSON.stringify(props.itemEdit.value));
    };

    const loading = ref(false);
    const onSubmit = () => {
      loading.value = true;
      const PromotionItem = JSON.parse(JSON.stringify(stateData));
      const data = {
        ...PromotionItem.value
      };
      axiosD
        .consultPriceUpdate(PromotionItem.value.id, data)
        .then(() => {
          loading.value = false;
          resetuserData();
          emit("editPromotionSuccess", true);
        })
        .catch(() => {
          emit("editPromotionSuccess", false);
          loading.value = false;
        });
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);
    return {
      stateData,
      loading,
      optionsStatus,
      laboratoriesOptions,
      laboratoriesPackOptions,
      testOptions,
      procedureOptions,
      diagnosticImagingOptions,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>