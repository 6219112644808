<template>
  <b-sidebar
    id="add-new-promotion-sidebar"
    :visible="isAddNewPromotionSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-promotion-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("Add") }} {{ $t("New") }} Consult Price</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group :label="$t('Form.Name')" label-for="name">
              <b-form-input
                id="name"
                v-model="stateData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Consult price name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Default -->
          <!-- <validation-provider
            #default="validationContext"
            name="Default"
            rules="required"
          >
            <b-form-group label="Default" label-for="default">
              <v-select
                id="default"
                v-model="stateData.default"
                :state="getValidationState(validationContext)"
                :options="optionsDefault"
                :clearable="false"
                label="text"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <b-form-group label="Total" label-for="price">
            <b-form-input
              id="price"
              :value="totalPrice"
              disabled
              type="number"
              placeholder="Consult price name"
            />
          </b-form-group>

          <!-- Price -->
          <validation-provider
            #default="validationContext"
            name="Price"
            rules="required"
          >
            <b-form-group label="Price" label-for="price">
              <b-form-input
                id="price"
                v-model="stateData.price"
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="Consult price name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- start Date-->
          <validation-provider
            #default="validationContext"
            name="Date from"
            rules=""
          >
            <b-form-group label="Date from" label-for="date_from">
              <flat-pickr
                id="date_from"
                v-model="stateData.date_start"
                :config="config"
                class="form-control"
                placeholder="MM-DD-YYYY"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- end Date -->
          <validation-provider
            #default="validationContext"
            name="Date to"
            rules=""
          >
            <b-form-group label="Date to" label-for="date_to">
              <flat-pickr
                id="date_to"
                v-model="stateData.date_end"
                :config="config"
                class="form-control"
                placeholder="MM-DD-YYYY"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- laboratories -->
          <validation-provider
            #default="validationContext"
            name="Laboratories"
            rules=""
          >
            <b-form-group label="Laboratories" label-for="laboratories">
              <v-select
                id="laboratories"
                v-model="stateData.laboratories"
                :state="getValidationState(validationContext)"
                :options="laboratoriesOptions"
                multiple
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- laboratories -->
          <validation-provider
            #default="validationContext"
            name="Laboratory Packs"
            rules=""
          >
            <b-form-group label="Laboratory Packs" label-for="laboratories">
              <v-select
                id="laboratories"
                v-model="stateData.laboratories_pack"
                :state="getValidationState(validationContext)"
                :options="laboratoriesPackOptions"
                multiple
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- tests -->
          <validation-provider
            #default="validationContext"
            name="Tests"
            rules=""
          >
            <b-form-group label="Tests" label-for="test">
              <v-select
                id="test"
                v-model="stateData.tests"
                :state="getValidationState(validationContext)"
                :options="testOptions"
                multiple
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- procedures -->
          <validation-provider
            #default="validationContext"
            name="Procedures"
            rules=""
          >
            <b-form-group label="Procedures" label-for="procedure">
              <v-select
                id="procedure"
                v-model="stateData.procedures"
                :state="getValidationState(validationContext)"
                :options="procedureOptions"
                multiple
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- diagnostic imagings -->
          <validation-provider
            #default="validationContext"
            name="Diagnostic Imagings"
            rules=""
          >
            <b-form-group label="Diagnostic Imagings" label-for="diagnostic">
              <v-select
                id="diagnostic"
                v-model="stateData.diagnostic_imagings"
                :state="getValidationState(validationContext)"
                :options="diagnosticImagingOptions"
                multiple
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Treatment -->
          <validation-provider
            #default="validationContext"
            name="Treatments"
            rules=""
          >
            <b-form-group label="Treatments" label-for="treatment">
              <v-select
                id="treatment"
                v-model="treatmentsSelected"
                :state="getValidationState(validationContext)"
                :options="
                  treatmentsOptions.filter(
                    (o) => treatmentsSelected.indexOf(o) < 0
                  )
                "
                multiple
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div
            v-for="(item, index) in stateData.treatments"
            :key="index"
            class="d-flex"
          >
            <input
              type="text"
              class="form-control"
              :value="item.name"
              disabled
            />
            <input
              type="number"
              class="form-control"
              v-model="item.cant"
              max="10"
              min="1"
            />
          </div>

          <!-- status -->
          <validation-provider
            #default="validationContext"
            name="Status"
            rules="required"
          >
            <b-form-group :label="$t('Form.Status')" label-for="status">
              <v-select
                id="status"
                v-model="stateData.status"
                :state="getValidationState(validationContext)"
                :options="optionsStatus"
                :reduce="(value) => value.value"
                label="text"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="loading"
            >
              <span v-if="!loading">{{ $t("Add") }}</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-danger"
              @click="hide"
            >
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SpinnerLoading from "@/components/SpinnerLoading";

import vSelect from "vue-select";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { computed, onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";

import axiosD from "@/services/admin/consults/consultPrices";
import axiosL from "@/services/admin/consults/laboratories";
import axiosT from "@/services/admin/consults/tests";
import axiosP from "@/services/admin/consults/procedures";
import axiosDI from "@/services/admin/consults/diagnosticImages";
import axiosTR from "@/services/admin/consults/treatments";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    SpinnerLoading,
    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewPromotionSidebarActive",
    event: "update:is-add-new-promotion-sidebar-active",
  },
  props: {
    isAddNewPromotionSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      config: {
        dateFormat: "m-d-Y",
        allowInput: true,
      },
    };
  },
  setup(props, { emit }) {
    const optionsStatus = [
      { text: "Active", value: true },
      { text: "Inactive", value: false },
    ];

    const optionsDefault = [
      { text: "Yes", value: true },
      { text: "No", value: false },
    ];

    const totalPrice = computed(() => {
      let totalPrice = 0;
      for (const iterator in stateData.value) {
        if (Array.isArray(stateData.value[iterator])) {
          stateData.value[iterator].forEach((item) => {
            item.cant
              ? (totalPrice += item.price * item.cant)
              : (totalPrice += item.price);
          });
        }
      }
      return totalPrice;
    });

    const treatmentsSelected = ref([]);
    watch(treatmentsSelected, (newValue, oldValue) => {
      if (newValue.length > oldValue.length) {
        const selected = newValue[newValue.length - 1];
        stateData.value.treatments.push({
          id: selected.id,
          name: selected.name,
          price: selected.price,
          cant: 1,
        });
      } else {
        const treatmentEliminated = oldValue.findIndex(
          (elemento) => newValue.indexOf(elemento) == -1
        );
        stateData.value.treatments.splice(treatmentEliminated, 1);
      }
    });

    onMounted(() => {
      getData();
    });

    const laboratoriesOptions = ref([]);
    const laboratoriesPackOptions = ref([]);
    const testOptions = ref([]);
    const procedureOptions = ref([]);
    const diagnosticImagingOptions = ref([]);
    const treatmentsOptions = ref([]);

    const getData = () => {
      axiosL.laboratoryList(200).then(({ data }) => {
        laboratoriesOptions.value = data;
        data.forEach((item) => {
          if (item.categories[0].price) {
            if (
              !laboratoriesPackOptions.value.some(
                (itemFind) => itemFind.id === item.categories[0].id
              )
            ) {
              laboratoriesPackOptions.value.push(item.categories[0]);
            }
          }
        });
      });
      axiosT.testList(20).then(({ data }) => {
        testOptions.value = data;
      });
      axiosP.procedureList(20).then(({ data }) => {
        procedureOptions.value = data;
      });
      axiosDI.diagnosticImageList(20).then(({ data }) => {
        diagnosticImagingOptions.value = data;
      });
      axiosTR.treatmentList(20).then(({ data }) => {
        treatmentsOptions.value = data;
      });
    };

    const stateDataTem = {
      name: "",
      default: false,
      price: null,
      date_start: null,
      date_end: null,
      laboratories: [],
      laboratories_pack: [],
      tests: [],
      procedures: [],
      diagnostic_imagings: [],
      treatments: [],
      status: "",
    };
    const stateData = ref(JSON.parse(JSON.stringify(stateDataTem)));

    const resetuserData = () => {
      stateData.value = JSON.parse(JSON.stringify(stateDataTem));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const loading = ref(false);
    const onSubmit = () => {
      loading.value = true;

      const data = {
        ...stateData.value,
        laboratories: createFinalArray(stateData.value.laboratories),
        laboratories_pack: createFinalArray(stateData.value.laboratories_pack),
        tests: createFinalArray(stateData.value.tests),
        procedures: createFinalArray(stateData.value.procedures),
        diagnostic_imagings: createFinalArray(
          stateData.value.diagnostic_imagings
        ),
      };
      axiosD
        .consultPromotionCreate(data)
        .then((res) => {
          loading.value = false;
          resetuserData();
          emit("createPromotion", res);
        })
        .catch(() => {
          loading.value = false;
          emit("createPromotion");
        });
    };

    const createFinalArray = (arrayBackend) => {
      const array = arrayBackend.map((item) => item.id);
      return array;
    };

    return {
      loading,
      stateData,
      optionsStatus,
      totalPrice,
      laboratoriesOptions,
      laboratoriesPackOptions,
      testOptions,
      procedureOptions,
      diagnosticImagingOptions,
      treatmentsOptions,
      treatmentsSelected,
      optionsDefault,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
