<template>
  <b-modal
    id="modal-lg"
    v-model="conditionPromotion"
    hide-footer
    hide-header
    centered
    size="lg"
    @hide="onHide"
  >
    <b-card no-body class="card-browser-states">
      <b-card-header>
        <div>
          <b-card-title>Campaign items</b-card-title>
        </div>
      </b-card-header>

      <!-- body -->
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="lg"
      ></b-overlay>
      <b-card-body>
        <div
          v-for="(item, i) in dataPromotionData"
          :key="i"
          class="browser-states"
        >
          <b-media no-body>
            <feather-icon class="mr-1 text-info" icon="GitBranchIcon" />
            <h6 class="align-self-center my-auto">
              {{ item.name }}
            </h6>
          </b-media>
          <div class="d-flex align-items-center">
            <span class="font-weight-bold text-body-heading mr-1">{{
              item.price
            }}</span>
          </div>
        </div>
      </b-card-body>
      <!--/ body -->
    </b-card>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BFormGroup,
  BAvatar,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BImg,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import SpinnerLoading from "@/components/SpinnerLoading";
import NotificationMixin from "@/mixins/NotificationMixin";

import vSelect from "vue-select";

import axiosP from "@/services/admin/consults/consultPrices";
export default {
  components: {
    BButton,
    BModal,
    BFormGroup,
    BAvatar,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BImg,
    BOverlay,

    ValidationProvider,
    ValidationObserver,

    SpinnerLoading,
    vSelect,
  },
  data() {
    return {
      conditionPromotion: true,
      dataPromotion: null,
      dataPromotionData: [],
      loading: false,
    };
  },
  props: {
    PromotionById: {
      type: Number,
      required: true,
    },
  },
  mixins: [NotificationMixin],
  mounted() {
    this.getPromotion();
  },
  methods: {
    onHide() {
      this.$emit("closeModalPromotion", false);
    },
    getPromotion() {
      this.loading = true;
      axiosP.consultPromoView(this.PromotionById).then(({ registro }) => {
        const {
          laboratories,
          laboratories_pack,
          diagnostic_imagings,
          tests,
          procedures,
          treatments,
        } = registro;
        const recipeDescription = [].concat(
          laboratories,
          laboratories_pack,
          diagnostic_imagings,
          procedures,
          tests,
          treatments
        );
        this.dataPromotionData = recipeDescription;
        this.dataPromotion = registro;
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss">
</style>